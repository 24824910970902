import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'Initial Decisions';
    const desc = ` `;
    return (
      <TodoSubList title={title} desc={desc} id="practice">
        <Head title={title} description={desc} url="/todo/practice/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
